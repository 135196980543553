
























import { Component, Vue, PropSync, Prop } from "vue-property-decorator";
import { buttonOptions } from "@/components/Buttons/buttonOptions";
@Component({
  components: {}
})
export default class ButonWhithList extends Vue {
  @PropSync("options") opciones!: buttonOptions;
  @Prop() icono_boton!: string;
  @Prop({ default: "primary" }) color_boton!: string;
  @Prop({ default: "" }) text_toolstrip!: string;

}
